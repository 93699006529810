#pzo__main {
  margin: 15px 0;
  padding-right: 25px;
  width: calc(100% - 255px);
  transform: translateX(255px);
  transition: all .5s ease;
}

.sidebar--closed #pzo__main {
  width: calc(100% - 100px);
  transform: translateX(100px);
  transition: all 0.7s cubic-bezier(0.4, 0, 0.2, 1);
}