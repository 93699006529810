.dashboard .dashboard__row {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin-bottom: 50px;
}

.dashboard__row>div {
  min-height: 240px;
}

.dashboard__row>div:first-child {
  color: var(--white);
  width: 60%;
  margin-right: 18px;
}

.dashboard__row>div:first-child .dashboard__info {
  display: flex;
  align-items: baseline;
}

.dashboard__row>div:first-child .dashboard__info svg {
  width: 16px;
  height: 16px;
  margin-right: 16px;
}

.dashboard__row>div:first-child .dashboard__info p {
  width: 100%;
  max-width: 370px;
}

.dashboard__row>div:first-child h2 {
  color: var(--white);
  margin-bottom: 30px;
}

.dashboard__row>div:first-child .searchbar {
  max-width: 100%;
  margin-bottom: 40px;
}

.dashboard__row>div:last-child {
  width: 40%;
}

.dashboard .campaigns {
  width: calc(100% + 25px);
}

.dashboard:not(.last-screenshots) .campaigns h2 {
  margin-bottom: 20px;
}

.dashboard .campaigns__total {
  display: block;
  padding-left: 25px;
}

.dashboard.last-screenshots .campaigns__update {
  width: calc(100% - 25px);
}

.dashboard .campaigns__update {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.dashboard.last-screenshots .campaigns__table .table__head::before {
  display: none;
}

/* Active Campaings */

.active-campaigns h2 {
  margin-bottom: 60px;
}

.active-campaigns h3 {
  padding-left: 25px;
  margin-bottom: 20px;
}

.active-campaigns .pzo__searchbar {
  max-width: 100%;
  margin-bottom: 50px;
  padding: 5px;
}

.active-campaigns .pzo__searchbar input {
  font-size: 16px;
  padding: 0px 25px;
}

.active-campaigns .pzo__searchbar .searchbar__icon {
  width: 50px;
  height: 50px;
}

/* History */

.storico .campaigns__total {
  display: block;
  padding-bottom: 20px;
}

/* Work in progress page */

.wip .wip__image {
  display: block;
  width: 100%;
  max-width: 768px;
  margin: auto;
}