#pzo__topbar {
    display: flex;
    align-items: center;
    margin-bottom: 70px;
}

#pzo__topbar>* {
    flex: none;
}

#pzo__topbar .topbar__close-sidebar {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33px;
    height: 33px;
    background-color: var(--white);
    box-shadow: 0px 4px 10px #0000000F;
    border-radius: 10px;
    margin-right: 20px;
}

#pzo__topbar .topbar__close-sidebar:hover svg {
    animation: sidebarToggleCloseAnimation 1s 2 ease-out;
    stroke: var(--blue);
}

#pzo__topbar .topbar__close-sidebar.sidebar-closed:hover svg {
    animation: sidebarToggleOpenAnimation 1s 2 ease-out;
}

#pzo__topbar .topbar__close-sidebar svg {
    width: 100%;
    height: 16px;
}

#pzo__topbar .topbar__customer-logo img{
    width: 100%;
    max-width: 54px;
    height: auto;
}

#pzo__topbar .topbar__searchbar-container {
    max-width: 533px;
    width: 100%;
}


@keyframes sidebarToggleCloseAnimation {
    0% {
        transform: translateX(-5px) scale(1.1);
    }

    100% {
        transform: translateX(0) scale(1);
    }
}

@keyframes sidebarToggleOpenAnimation {
    0% {
        transform: translateX(5px) scale(1.1);
    }

    100% {
        transform: translateX(0) scale(1);
    }
}