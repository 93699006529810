.pzo__searchbar {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    position: relative;
    max-width: 533px;
    width: 100%;
    background-color: var(--white);
    padding: 3px;
    box-shadow: 0px 5px 10px #ff00991A;
    border-radius: 99px;
}

.pzo__searchbar .searchbar__icon {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;
    background-color: var(--green);
    border-radius: 9999px;
}

.pzo__searchbar .searchbar__icon svg {
    width: 100%;
    height: 20px;
}

.pzo__searchbar>div:last-child {
    flex: 1;
    display: block;
    width: unset;
    border: none;
    outline: none;
    border-color: unset;
    border-width: 0;
    box-shadow: unset;
    border-radius: 9999px;
}

.pzo__searchbar input {
    padding: 0px 15px;
    width: 100% !important;
    max-width: 100%;
    width: unset;
    border: none;
    outline: none;
    border-color: unset;
    border-width: 0;
    box-shadow: unset;
    border-radius: 9999px;
}

.pzo__searchbar .searchbar__clear {
    display: none;
    cursor: pointer;
    width: 28px;
    height: 28px;
    opacity: 0.9;
    margin: 0 10px;
}

.pzo__searchbar .searchbar__clear.true,
.pzo__searchbar .searchbar__clear.visible {
    display: block;
}

.pzo__searchbar input,
.pzo__searchbar input::placeholder {
    font-size: 15px;
    font-weight: 300;
    color: var(--blue);
}