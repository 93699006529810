.users-management .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 60px;
}

.users-management .users__table {
    border-radius: 26px;
}

.users__table .pzo__table-filter {
    margin: 10px 35px 35px 0;
}

.users__table .pzo__table-filter>div:first-child {
    border-radius: 26px;
}

.users__table .pzo__table-filter input::placeholder {
    font-size: 15px;
    font-weight: 300;
    color: var(--blue);
}

.users__table .pzo__table-filter input {
    font-size: 15px;
    font-weight: 300;
    color: var(--blue);
    padding: 12px 15px;
    width: 100% !important;
    max-width: 100%;
    width: unset;
    border: none;
    outline: none;
    border-color: unset;
    border-width: 0;
    box-shadow: unset;
    border-radius: 9999px;
}

.users-management .edit-user,
.users-management .delete-user {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 500;
}

.users-management .edit-user svg,
.users-management .delete-user svg {
    width: 22px;
    height: auto;
    margin-right: 8px;
}

.users-management .edit-user {
    color: #ff0099;
}

.users-management .delete-user {
    color: #FF632F;
}

.users-management .min-w {
    display: block;
    min-width: 175px;
}

.users-management .user-form__title {
    margin-bottom: 20px;
}

.users-management .user-form__form {
    width: 100%;
    max-width: 630px;
    padding: 24px;
    box-shadow: 0px 20px 30px #6a163b26;
    border-radius: 26px;
}

.users-management .user-form__form.disabled {
    opacity: 0.6;
    pointer-events: none;
}

.users-management .user-form__form label {
    font-weight: 500;
    margin-right: 50px;
}


.users-management .user-form__form label span {
    color: #FF632F;
    padding-right: 5px;
}

.users-management .user-form__form .row {
    position: relative;
    justify-content: unset;
    margin-bottom: 20px;
}

.users-management .user-form__form input {
    font-family: 'Rubik', sans-serif;
    width: 100%;
    border: none;
    outline: none;
    padding: 10px 15px;
    box-shadow: 0px 5px 10px #6a163b14;
    border-radius: 14px;
}

.users-management .user-form__form .tremor-base {
    width: fit-content;
    outline: none;
    border: none;
    border-radius: 14px;
    box-shadow: unset;
}

.users-management .user-form__form .tremor-base input {
    cursor: pointer;
}

.users-management .user-form__form .cta.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.users-management .user-form__form .cta {
    width: fit-content;
    margin-left: auto;
}