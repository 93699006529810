.alert {
    position: absolute;
    right: 10px;
    top: 5px;
    cursor: pointer;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 999px;
    transition: all 0.3s ease-in-out;
}

.alert .alert__text {
    font-weight: 700;
    color: var(--white);
    font-size: 13px;
    line-height: 1;
    display: block;
    width: 0px;
    overflow: hidden;
    white-space: pre;
    padding: 0;
}

.alert .alert__icon svg {
    display: block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
}

.alert:hover {
    background-color: #E74C3C;
    width: fit-content;
}

.alert:hover .alert__text {
    width: 100%;
    padding-left: 10px;
}

.alert:hover .alert__icon {
    padding-left: 5px;
}

.alert:hover .alert__icon svg {
    stroke: white;
}