.login {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(45deg, #f2f3f8, #f2f7f7);
}

.login .form {
    width: 100%;
    max-width: 450px;
    border: 1px solid #ffffff;
    border-radius: 26px;
    box-shadow: 0px 20px 80px #6a163b8C;
}

.login .form .form__header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    padding: 35px 45px;
    border-radius: 26px 26px 0 0;
}

.login .form .form__header svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.login .form .form__header img {
    height: 72px;
    width: auto;
}

.login .form .form__header img.app-logo {
    margin-left: -8x;
}

.login .form .form__body {
    background-color: rgba(249, 249, 249, 0.6);
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
    padding: 40px 20px 30px 20px;
    border-radius: 0 0 26px 26px;
}

.login .form .form__body .h1 {
    display: block;
    width: 100%;
    text-align: center;
    font-weight: 500;
    margin-bottom: 25px;
}

.login .form .form__body input[type="password"],
.login .form .form__body .tr-relative {
    width: 100%;
    outline: none;
    border: none;
    border-radius: 16px;
    margin: 0 0 15px 0;
    box-shadow: none;
}

.login .form .form__body input {
    font-family: 'Rubik', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #6a163b80;
    padding: 13px 16px;
}

.login .form .form__body input::placeholder {
    font-family: 'Rubik', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #6a163b80;
}

.login .form .form__body .cta {
    font-size: 18px;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    margin: 35px 0 0 0;
    width: 100%;
}

.login .form .form__password-reset {
    text-align: center;
    padding-top: 15px;
    color:var(--blue);
}